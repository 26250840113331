import _defineProperty from "/builds/aoiChV12/1/ms/ecom/deep/presentation-react/apps/next-commerce/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

/**
 * Adds a 'level' property to each item and its nested children within groups.
 * @param {Group[]} input - The array of groups to process.
 * @returns {Group[]} - The modified array of groups with 'level' properties added to items.
 */
var addLevelKey = function addLevelKey() {
  var input = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

  // Return empty array if input is not defined or not an array
  if (!input || !Array.isArray(input)) {
    console.error('Invalid input. Expected an array.');
    return [];
  }
  /**
   * Recursively processes items to add 'level' property and process nested children.
   * @param {Item[]} items - The array of items to process.
   * @param {number} level - The current level of the items in the hierarchy.
   * @returns {Item[]} - The modified array of items with 'level' properties added.
   */


  var processItems = function processItems() {
    var items = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var level = arguments.length > 1 ? arguments[1] : undefined;
    return items.map(function (item) {
      return _objectSpread(_objectSpread({}, item), {}, {
        level: level,
        childrens: item.childrens ? processItems(item.childrens, level + 1) : undefined
      });
    });
  };

  return input.map(function (group) {
    return _objectSpread(_objectSpread({}, group), {}, {
      level: 0,
      items: processItems(group.items, 1)
    });
  });
};

export default addLevelKey;